
// Header.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faShoppingCart, faHome } from "@fortawesome/free-solid-svg-icons";
import store from "../../assets/images/store.png";
import { useNavigate } from "react-router-dom";

const CustomerHeader = ({ shopData, searchQuery, setSearchQuery, handleCartClick, handleContinueShopping }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between bg-white p-4 shadow-md flex-nowrap">
      {/* Home Icon */}
      <button
        onClick={handleContinueShopping}
        className="text-gray-600 hover:text-gray-800 focus:outline-none flex-shrink-0"
      >
        <FontAwesomeIcon icon={faHome} className="text-lg sm:text-xl" />
      </button>

      {/* Shop Info - Responsive Alignment */}
      <div className="flex items-center flex-grow mx-2 truncate justify-start sm:justify-center">
        <img
          src={store}
          alt="Shop"
          className="w-8 h-8 sm:w-10 sm:h-10 object-cover rounded-lg mr-2"
        />
        <h1 className="text-sm sm:text-base font-semibold text-gray-800 truncate">
          {shopData?.shopName}
        </h1>
      </div>

      {/* Cart and Search Icons */}
      <div className="flex items-center space-x-2 flex-shrink-0">
        {/* Search Input with Icon */}
        <div className="flex items-center bg-gray-100 rounded-lg px-2 py-1 w-28 sm:w-64">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-500 text-base sm:text-lg mr-2"
          />
          <input
            type="text"
            placeholder="Search..."
            className="bg-transparent outline-none w-full text-gray-600 placeholder-gray-500 text-sm"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>

        {/* Cart Icon */}
        <button
          className="text-gray-600 hover:text-gray-800 focus:outline-none flex-shrink-0"
          onClick={handleCartClick}
        >
          <FontAwesomeIcon
            icon={faShoppingCart}
            className="text-lg sm:text-xl"
          />
        </button>
      </div>
    </div>
  );
};

export default  CustomerHeader;