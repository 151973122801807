import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
import firebase from "firebase/compat/app";


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const apiClient = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/json',
    }

});

apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('idToken')
        console.log(apiBaseUrl);
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config;
    },
    error => Promise.reject(error)
);

apiClient.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status == 401) {
            // Handle 401 response with creating a new access token
            const idToken = localStorage.getItem('idToken')
            if (!idToken){
                console.error("There's no id token found, logging out")
            }
            const response = await axios.put(apiBaseUrl + '/refresh_token', {
                headers: {
                    'Content-Type': 'application/json',  
                    'Authorization': 'Bearer ' + idToken
                }
            }
            )
            localStorage.setItem('idToken', response.data.idToken)

            console.log('error config = ' + JSON.stringify(error.config))
        }

        return Promise.reject(error);
    }
)

export default apiClient;
