import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import signin_bg from '../../assets/images/signin_bg.jpg';
import axios from 'axios';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const Signin = () => {
  const [emailEntered, setEmailEntered] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {

    const idToken = localStorage.getItem('idToken')
    if (idToken) {
      navigate('/dashboard')
    }
  })

  const handleLoginClick = async (e) => {
    e.preventDefault();
  
    if (!emailEntered) {
      setEmailEntered(true);
    } else {
      if (emailOrMobile && password) {
        console.log("Email or Mobile:", emailOrMobile);
        console.log("Password:", password);
  
        const sendData = async () => {
          try {
            const response = await axios.post(apiBaseUrl + '/login', {
              email: emailOrMobile,
              password: password,
            });
            console.log("Response Data:", response.data);
            if ("userData" in response.data) {
              localStorage.setItem('idToken', response.data.userData.idToken)
                navigate('/dashboard');
             } else {
               console.log("Userdata does not exist");
             }

          } catch (error) {
            console.error("Error:", error.response?.data || error.message);
          }
          
        };
  
        await sendData(); 
      }
    }
  };
  

  return (
    <div
      className="h-screen w-full bg-cover bg-center flex items-center justify-center p-4"
      style={{ backgroundImage: `url(${signin_bg})` }}
    >
      <div className="bg-white p-8 md:p-10 rounded-lg shadow-lg max-w-md w-full relative">
       
        <h2 className="text-2xl font-bold mb-4 text-center md:text-left">My Shop Digital</h2>
        <p className="text-2xl font-semibold text-gray-700 mb-6 text-center md:text-left">Log in</p>

        <form onSubmit={handleLoginClick}>
          
          <div className="mb-4 mt-4">
            <label className="block text-gray-700 mb-2 mt-5 md:mt-4">Email or Mobile Number</label>
            <input
              type="text"
              placeholder="Enter your email or mobile number"
              value={emailOrMobile}
              onChange={(e) => setEmailOrMobile(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          {/* Conditional Password Field */}
          {emailEntered && (
            <div className="mb-4">
              <label className="block text-gray-700 mb-2 mt-4">Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                required
              />
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 mt-4"
          >
            {emailEntered ? 'Log In' : 'Next'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
