import Lottie from 'lottie-react';
import shopAnimationData from '../../assets/animations/shopAnimation.json';
import { useNavigate } from 'react-router-dom';
const AddShopDashboard = () => {

    const navigate = useNavigate();

    const handleAddShop = () => {
        navigate('/add-shop');
    };

    return (

        <div>
            <div className="flex justify-between items-center mb-4 mt-6">
                <div className="relative w-1/3">
                    <input
                        type="text"
                        placeholder="Search Shop..."
                        className="border rounded py-2 pl-10 pr-4 placeholder-gray-400 ms-6"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute left-3 top-2 w-5 h-5 text-gray-400 ms-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 4a7 7 0 107 7 7 7 0 00-7-7zm0 14a7 7 0 100-14 7 7 0 000 14zm0 0l7 7" />
                    </svg>
                </div>

                <button
                    className="bg-blue-500 text-white rounded py-2 px-4 me-6"
                    onClick={handleAddShop}
                >
                    + Add New Shop
                </button>
            </div>
            <div className="flex justify-center">
                <Lottie animationData={shopAnimationData} loop={true} style={{ width: 300, height: 300 }} />
            </div>

            <div className="flex flex-col items-center mt-4">
                <p className="text-center font-bold text-black text-2xl">
                    Add your Shop
                </p>
                <p className="text-center text-black text-lg mt-2">
                    Start showcasing your shop! Add it here and promote on social media to reach more customers.
                </p>
            </div>

            <div className="flex justify-center mt-6 space-x-4">
                <button
                    className="bg-blue-500 text-white rounded py-2 px-4"
                    onClick={handleAddShop}
                >
                    Add New Shop
                </button>
            </div>
        </div>


    );

}
export default AddShopDashboard;