import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiClient from "../../api/apiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import store from "../../assets/images/store.png";
import CartPage from "../shops/CartPage";
import Header from "../customer/CustomerHeader"; 

const CustomerHome = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false); // State for the side menu
  const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product for modal
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track the current image index
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [shopData, setShopData] = useState({})
  const location = useLocation();
  const navigate = useNavigate();

  const { shopUrl } = useParams(); // Correctly extract shopUrl


  useEffect(() => {
    console.log('shop url = ', {shopUrl})
    if (shopUrl) {
      const fetchData = async () => {
        try {
          const response = await apiClient.get(
            `/myshop?shopUrl=${shopUrl}`
          );
          setProducts(response.data.products);
          setShopData(response.data.shop)
          console.log('response = ' + JSON.stringify(response.data))
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      fetchData();
    }
  }, [shopUrl]);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleAddToCart = (product) => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
  
    // Check if the product already exists in the cart
    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id
    );
 
    if (existingProductIndex !== -1) {
      // If the product exists, increase its quantity
      cart[existingProductIndex].quantity += product.quantity || 1; // Default to 1 if product.quantity is undefined
    } else {
      // If the product is new, add it to the cart with quantity
      cart.push({ ...product, quantity: product.quantity || 1 }); // Default to 1 if product.quantity is undefined
    }
  
    // Update localStorage
    localStorage.setItem("cart", JSON.stringify(cart));
    console.log("Cart:", cart);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product); // Open the modal with the product details
    setCurrentImageIndex(0); // Reset image index to 0 when a new product is clicked
  };

  const handleCloseModal = () => {
    setSelectedProduct(null); // Close the modal
  };

  const handleNextImage = () => {
    if (selectedProduct?.imageUrls.length > 1) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % selectedProduct.imageUrls.length
      );
    }
  };

  const handlePrevImage = () => {
    if (selectedProduct?.imageUrls.length > 1) {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + selectedProduct.imageUrls.length) %
          selectedProduct.imageUrls.length
      );
    }
  };

  const handleCartClick = () => {
    navigate("/cart", { state: { shopData } });
  };

  const handleContinueShopping = () => {
    console.log("I am here");
    // TODO: Go to same path with same dynamic shopUrl
  };

  return (
    <div className="min-h-screen bg-gray-50 relative">
      {/* Top Section with Shop Image and Name */}
      <Header 
        shopData={shopData} 
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleCartClick={handleCartClick}
        handleContinueShopping={handleContinueShopping}
      />

      {/* Side Menu */}
      {isSideMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10">
          <div className="fixed top-0 right-0 h-full bg-white w-80 shadow-lg z-20">
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-lg font-semibold">Search Products</h2>
              <button
                onClick={() => setIsSideMenuOpen(false)} // Close the side menu
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>
            </div>
            <div className="p-4">
              <div className="relative">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-3 text-gray-400"
                />
                <input
                  type="text"
                  placeholder="Search products..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Conditional Rendering of Cart Page or Product Grid */}
      {isCartVisible ? (
        <CartPage shopData={shopData}/> // Render CartPage if cart is visible
      ) : (
        <div className="p-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredProducts.length === 0 ? (
            <div className="col-span-full text-center text-gray-500">
              No products found
            </div>
          ) : (
            filteredProducts.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductClick(product)} // Open product details in modal on click
                className="relative bg-white rounded-2xl shadow-lg hover:shadow-xl transform transition-transform duration-300 hover:scale-105 cursor-pointer"
              >
                {/* Product Image */}
                <div className="overflow-hidden rounded-t-2xl">
                  <img
                    src={product.imageUrls[0]}
                    alt={product.name}
                    className="w-full h-48 object-cover transition-transform duration-500 hover:scale-110"
                  />
                </div>

                {/* Product Details */}
                <div className="p-4 flex flex-col items-center">
                  {/* Product Name */}
                  <h3 className="text-lg font-semibold text-gray-900 truncate text-center">
                    {product.name}
                  </h3>

                  {/* Product Price */}
                  <p className="text-gray-600 text-sm mt-2">
                    ₹ {product.price}
                  </p>
                </div>

                {/* Add to Cart Icon */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  className="absolute bottom-4 right-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white w-10 h-10 rounded-full shadow-lg flex items-center justify-center transition-transform duration-300 hover:scale-110"
                >
                  <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                </button>
              </div>
            ))
          )}
        </div>
      )}

      {/* Modal for Product Details */}
      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-30">
          <div className="bg-white p-4 sm:p-8 rounded-2xl max-w-sm sm:max-w-lg w-full shadow-xl relative transform transition-transform duration-300 ease-in-out scale-95 hover:scale-100 mx-4">
            {/* Close Button */}
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>

            <div className="flex flex-col space-y-4">
              {/* Product Name and Price */}
              <h2 className="text-xl font-semibold text-gray-900 text-center">
                {selectedProduct.name}
              </h2>
              <p className="text-lg text-gray-700 text-center">
                ₹ {selectedProduct.price}
              </p>

              {/* Product Image Slider */}
              <div className="flex justify-center items-center space-x-4">
                <button
                  onClick={handlePrevImage}
                  className="text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </button>

                <img
                  src={selectedProduct.imageUrls[currentImageIndex]}
                  alt={selectedProduct.name}
                  className="w-48 h-48 object-cover rounded-lg"
                />

                <button
                  onClick={handleNextImage}
                  className="text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </button>
              </div>

              {/* Product Description */}
              <p className="text-gray-600 text-sm sm:text-base mt-4 text-center">
                {selectedProduct.description}
              </p>

              {/* Add to Cart Button */}
              <button
                onClick={() => handleAddToCart(selectedProduct)}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white w-full py-2 rounded-lg shadow-lg hover:bg-purple-600 transition duration-300"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerHome;
