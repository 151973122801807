import axios from 'axios';
import React, { useState } from 'react';
import apiClient from '../../api/apiClient';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const AddShop = () => {
  const [shopName, setShopName] = useState('');
  const [address, setAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [shopType, setShopType] = useState('');
  const [shopDescription, setShopDescription] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ shopName, address, contactNumber, shopType, shopDescription });

    const sendData = async () => {
      try {
        const response = await apiClient.post(apiBaseUrl + '/shops', {
          shopName: shopName,
          location: address,
          phoneNumber: contactNumber,
          description: shopDescription
        })
      } catch (error) {
        console.log('Following error occurred: ' + error.response.data.error)
      }

    };
    await sendData();
  };

  return (
    <div className="min-h-screen bg-[#e1e2e5] flex items-center justify-center">
      <div className="w-11/12 max-w-lg p-8 bg-white rounded-lg shadow-lg border border-gray-200">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Add Your Shop</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="shopName">
              Shop Name
            </label>
            <input
              type="text"
              id="shopName"
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none  p-1 transition duration-200 ease-in-out bg-gray-50 placeholder-gray-500"
              placeholder="Enter shop name"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none  p-1 transition duration-200 ease-in-out bg-gray-50 placeholder-gray-500"
              placeholder="Enter shop address"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="contactNumber">
              Contact Number
            </label>
            <input
              type="tel"
              id="contactNumber"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none  p-1 transition duration-200 ease-in-out bg-gray-50 placeholder-gray-500"
              placeholder="Enter contact number"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="shopType">
              Shop Type
            </label>
            <select
              id="shopType"
              value={shopType}
              onChange={(e) => setShopType(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none p-1 transition duration-200 ease-in-out bg-gray-50 placeholder-gray-500"
            >
              <option value="">Select Shop Type</option>
              <option value="Clothing">Clothing</option>
              <option value="Footwear">Footwear</option>
              <option value="Toys">Toys</option>
              <option value="Electronics">Electronics</option>
              <option value="Grocery">Grocery</option>
            </select>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="shopDescription">
              Shop Description
            </label>
            <textarea
              id="shopDescription"
              value={shopDescription}
              onChange={(e) => setShopDescription(e.target.value)}
              rows="4"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none p-1 transition duration-200 ease-in-out bg-gray-50 placeholder-gray-500"
              placeholder="Provide a brief description of your shop..."
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 focus:outline-none  transition duration-200"
          >
            Add Shop
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddShop;
