import React, { useEffect, useState } from 'react';
import apiClient from '../../api/apiClient';
import ShopDashboard from './ShopDashboard';
import AddShopDashboard from './AddShopDashboard';


// Dasboard is a dashboard shown to user in case a shop is not created, if shop is created, he shall be redirected to
// shop dashboard to add products

const Dashboard = () => {
  const [shopData, setShopData] = useState({}); // State to hold shops data
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get('/shops');
        const shops = response.data
        setShopData(shops || []); // Store the shops array in state
        
      } catch (error) {
        console.error('Following error occurred: ' + error);
      }
    };
    fetchData();

  }, []);
  return (
    <div>
    {shopData.length === 0 ? (
      <AddShopDashboard />
    ) : (<ShopDashboard shopData={shopData[0]}/>)
    }
  </div>
  );
  
};

export default Dashboard;
