import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableImage from "../common/DraggableImage";
import apiClient from "../../api/apiClient";

const ProductForm = ({
  shopData,
  initialProductData = {},
  viewMode = "add",
}) => {
  const [images, setImages] = useState([]);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productDescription, setProductDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    message: "",
    success: false,
  });

  const isEditMode = viewMode === "edit";
  const isViewMode = viewMode === "view";

  useEffect(() => {
    // Load initial data if in edit or view mode
    console.log('shop data = ' + JSON.stringify(shopData))
    console.log(initialProductData)
    if (initialProductData) {
      setProductName(initialProductData.name || "");
      setProductPrice(initialProductData.price || 0);
      setProductDescription(initialProductData.description || "");
      setImages(
        (initialProductData.imageUrls || []).map((url) => ({
          src: url,
        }))
      );
    }

    // Set the document title based on the view mode
    document.title = isEditMode
      ? "Edit Product"
      : isViewMode
      ? "View Product"
      : "Add New Product";
  }, [isEditMode, isViewMode, initialProductData]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setImages([...images, ...newImages]);
  };

  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prevent submission in view mode
    if (isViewMode) return;
    setLoading(true);

    const shop_id = shopData.shopId;
    const formData = new FormData();
    formData.append("name", productName);
    formData.append("price", productPrice);
    formData.append("description", productDescription);
    images.forEach((image, index) => formData.append("images", image.file));

    try {
      const response = isEditMode
        ? await apiClient.put(
            `/shops/products/${initialProductData.id}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
        : await apiClient.post(`/shops/${shop_id}/products`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

      if (response.status === (isEditMode ? 200 : 201)) {
        setPopup({
          show: true,
          message: `Product ${isEditMode ? "updated" : "added"} successfully`,
          success: true,
        });
        setTimeout(
          () => setPopup({ show: false, message: "", success: false }),
          3000
        );
        if (!isEditMode) {
          setProductName("");
          setProductPrice(0);
          setProductDescription("");
          setImages([]);
        }
      }
    } catch (error) {
      setPopup({
        show: true,
        message: "Unable to save product, please contact support!",
        success: false,
      });
      console.error("Error occurred when saving product:", error);
    } finally {
      setLoading(false);
    }
  };

  const moveImage = useCallback((fromIndex, toIndex) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(fromIndex, 1);
      updatedImages.splice(toIndex, 0, movedImage);
      return updatedImages;
    });
  }, []);

  return (
    <div className="flex">
      <div className="flex-1 p-6">
        <h2 className="text-xl font-bold mb-4">
          {isEditMode ? "Edit Product" : isViewMode ? "View Product" : "Add New Product"}
        </h2>

        {loading ? (
          <div className="flex items-center justify-center h-screen">
            <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
            <p className="ml-4 text-gray-700">
              {isEditMode ? "Updating" : "Adding"} product, please wait...
            </p>
          </div>
        ) : (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                className="block mb-2 text-gray-600 text-base"
                htmlFor="productName"
              >
                Product Name
              </label>
              <input
                type="text"
                id="productName"
                className="border rounded w-full py-2 px-3"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                disabled={isViewMode}
                required
              />
            </div>
            <div>
              <label
                className="block mb-2 text-gray-600 text-base"
                htmlFor="productDescription"
              >
                Description
              </label>
              <textarea
                id="productDescription"
                className="border rounded w-full py-2 px-3"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                disabled={isViewMode}
                required
              />
            </div>
            <div>
              <label
                className="block mb-2 text-gray-600 text-base"
                htmlFor="productPrice"
              >
                Price
              </label>
              <input
                type="number"
                id="productPrice"
                className="border rounded w-full py-2 px-3"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                disabled={isViewMode}
                required
              />
            </div>
            <div>
              <DndProvider backend={HTML5Backend}>
                <div>
                  {/* Only show upload section if not in view mode */}
                  {viewMode !== "view" && (
                    <div>
                      <label
                        className="block mb-2 text-gray-600 text-base"
                        htmlFor="productImages"
                      >
                        Upload images
                      </label>
                      <input
                        type="file"
                        id="productImages"
                        className="border rounded w-full py-2 px-3"
                        multiple
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                  )}
                  <div className="flex flex-wrap gap-4 mt-4">
                    {images.map((image, index) => (
                      <DraggableImage
                        key={index}
                        image={image}
                        index={index}
                        moveImage={moveImage}
                        deleteImage={deleteImage}
                        isViewMode={isViewMode}
                      />
                    ))}
                  </div>
                </div>
              </DndProvider>
            </div>
            <div>
              <button
                type="submit"
                className="bg-blue-500 text-white rounded py-2 px-4"
                hidden={isViewMode}
              >
                {isEditMode ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        )}
        {popup.show && (
          <div
            className={`fixed top-4 right-4 p-4 rounded shadow-md ${
              popup.success ? "bg-green-500" : "bg-red-500"
            } text-white`}
          >
            <p>{popup.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductForm;