import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Setting = () => {
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const { shopData } = location.state || {}; // Default to empty object if no data
  //console.log(shopData);  // Accessing passed shopData

  const [shopName, setShopName] = useState('')
  const [phoneNumber, setphoneNumber] = useState('');
  const [shopUrl, setshopUrl] = useState('');
  const [description,  setdescription] = useState('');

  // Effect to initialize form values with shopData
  useEffect(() => {
    // console.log(shopData);  // Add this to see the data structure
    if (shopData) {
      setShopName(shopData.shopName || ''); // Initialize form data if shopData exists
      setphoneNumber(shopData.phoneNumber || '');
      setshopUrl(shopData.shopUrl || '');
      setdescription(shopData.description || '');
    }
  }, [shopData]);

  const handleSaveSettings = () => {
    setIsEditing(false);
    // Make an API call here to save the updated settings
    console.log("Saved settings:", { shopName, location, phoneNumber, shopUrl, description  });
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
        <h3 className="text-xl font-semibold mb-4">Store Settings</h3>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Shop Name</label>
          {isEditing ? (
            <input
              type="text"
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <p className="mt-1 text-lg font-medium">{shopName}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          {isEditing ? (
            <textarea
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <p className="mt-1 text-lg font-medium">{description}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Contact Number</label>
          {isEditing ? (
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <p className="mt-1 text-lg font-medium">{phoneNumber}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Store Link</label>
          {isEditing ? (
            <input
              type="text"
              value={shopUrl}
              onChange={(e) => setshopUrl(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <p className="mt-1 text-lg font-medium">{shopUrl}</p>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          {isEditing ? (
            <>
              <button
                onClick={handleSaveSettings}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
            >
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Setting;