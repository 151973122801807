import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faChevronLeft, faPlus, faMinus, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../customer/CustomerHeader"; 
const CartPage = () => {
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const shopData = location.state?.shopData;

  // Fetch cart data from localStorage on mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    console.log('storedCart:', storedCart);
    console.log('shopData:', shopData);
    // Ensure each product has a quantity set to 1 if not present
    const updatedCart = storedCart.map((product) => ({
      ...product,
      quantity: product.quantity || 1, // Default quantity to 1 if not defined
    }));
  
    // Update localStorage only if the cart was modified
    if (JSON.stringify(storedCart) !== JSON.stringify(updatedCart)) {
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  
    setCart(updatedCart);
  }, []);
    

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
  
    // Debugging Logs
    console.log("Removing product with ID:", productId);
    console.log("Cart before removal:", cart);
    console.log("Cart after removal:", updatedCart);
  
    // Update state and localStorage
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // Increase the quantity of a product in the cart
  const handleIncreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) =>
      item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // Decrease the quantity of a product in the cart
  const handleDecreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) =>
      item.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // Navigate back to the customer home page
  const handleContinueShopping = () => {
    console.log("I am here")
    // TODO: Go back to customer home or shop page with dynamic url
  };

  // Calculate the total price dynamically based on quantity
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // Handle Buy Now button click
  const handleBuyNow = () => {
    // Construct the WhatsApp URL with the shop's phone number
    const phoneNumber = shopData.phoneNumber;
    const message = `Hello, I'd like to purchase the following items: \n\n${cart.map((item) => `${item.name} (Quantity: ${item.quantity})`).join("\n")}`;
    
    // Open WhatsApp chat with the phone number and pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    // Open the URL in a new window/tab
    window.open(whatsappUrl, "_blank");
  };

  const handleCartClick = () => {
    navigate("/cart");
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
     <Header 
        shopData={shopData} 
        searchQuery={""} // No search functionality needed here, pass empty string
        setSearchQuery={() => {}}
        handleCartClick={() => {navigate(-1)}}
        
      />
      
      <div className="max-w-4xl mx-auto p-6 rounded-xl shadow-lg">
        {cart.length === 0 ? (
          // Show this when the cart is empty
          <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
            {/* Cart Icon Animation */}
            <div className="relative w-32 h-32 mb-6">
              <div className="absolute inset-0 animate-pulse">
                <div className="w-full h-full bg-gradient-to-r from-blue-500 to-purple-600 rounded-full opacity-50"></div>
              </div>
              <FontAwesomeIcon
            
                icon={faShoppingCart}
                size="3x"
                className="text-white absolute inset-0 m-auto"
              />
            </div>

            {/* Empty Cart Message */}
            <div className="text-center">
              <h1 className="text-3xl font-semibold text-gray-800 mb-4">
                Your Cart is Empty!
              </h1>
              <p className="text-lg text-gray-600">
                It seems like you haven't added any products to your cart yet. Start shopping to fill it up!
              </p>
            </div>

            {/* Button to Navigate Back to Home */}
            <button
              onClick={handleContinueShopping}
              className="bg-blue-500 text-white p-3 rounded-lg mt-6"
            >
              Continue Shopping
            </button>
          </div>
        ) : (
          // Show this when the cart has products
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Your Cart</h2>
            {cart.map((product) => (
              <div
                key={product.id}
                className="flex items-center justify-between p-4 mb-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow ease-in-out"
              >
                <div className="flex items-center space-x-4">
                  <img
                    src={product.imageUrls[0]}
                    alt={product.name}
                    className="w-24 h-24 object-cover rounded-lg border-2 border-gray-200"
                  />
                  <div>
                    <h3 className="font-semibold text-gray-900">{product.name}</h3>
                    <p className="text-gray-600 text-sm">₹ {product.price}</p>
                  </div>
                </div>

                {/* Quantity Controls */}
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleDecreaseQuantity(product.id)}
                    className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <span className="text-lg font-semibold">{product.quantity}</span>
                  <button
                    onClick={() => handleIncreaseQuantity(product.id)}
                    className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>

                {/* Remove Button */}
                <button
                    onClick={() => handleRemoveFromCart(product.id)}
                    className="text-red-500 hover:text-red-700"
                    >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            ))}

            {/* Total Price and Buy Now Button */}
            <div className="mt-6 flex justify-between items-center">
              <div className="text-right font-semibold text-lg text-gray-800">
                Total: ₹ {calculateTotalPrice()}
              </div>
              <button
                onClick={handleBuyNow}
                className="bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Buy Now
              </button>
            </div>
          </div>
        )}

        {/* Back Button */}
        <div className="mt-6 flex justify-between items-center">
          <button
            className="bg-blue-500 text-white p-2 rounded-lg"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartPage;