import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../../assets/videos/5889074-hd_1920_1080_25fps.mp4'; 
import beauty from '../../assets/images/beauty.jpg'; 
import cloth from '../../assets/images/cloth.jpg'; 
import shoes from '../../assets/images/shoes.jpg'; 
import sunglasses from '../../assets/images/sunglasses.jpg'; 

const Home = () => {
    const images = [beauty, cloth, shoes, sunglasses, beauty, cloth, shoes, sunglasses];

    return (
        <>
            <div className="relative h-screen overflow-hidden bg-black">
                {/* Background Video */}
                <video
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    autoPlay
                    loop
                    muted
                >
                    <source src={hero} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>

                {/* Overlay Text Positioned to Bottom Left */}
                <div className="absolute bottom-20 left-10 w-[600px] p-5 rounded-lg text-left z-10">
                    <div className="text-white text-6xl font-semibold mb-4 whitespace-normal">
                        Online Presence at a 
                    </div>
                    <div className="text-white text-6xl font-semibold mb-4 mt-5 whitespace-normal">
                        Fraction of the Cost
                    </div>
                    <button className="bg-blue-500 text-white text-lg font-semibold py-2 px-6 rounded-full hover:bg-blue-600">
                        Start Free Trial
                    </button>
                </div>

                {/* Navbar */}
                <nav className="absolute top-0 left-0 w-full p-5 flex justify-between items-center">
                    <div className="text-white text-xl font-bold">Logo</div>
                    <div className="space-x-4">
                        <a href="#" className="text-white hover:text-gray-300">Home</a>
                        <a href="#" className="text-white hover:text-gray-300">About</a>
                        <a href="#" className="text-white hover:text-gray-300">Services</a>
                        <a href="#" className="text-white hover:text-gray-300">Contact</a>
                        <Link to="/login" className="text-white hover:text-gray-300">Sign in</Link>

                    </div>
                </nav>
            </div>

            {/* White Background Div Below the Hero Area */}
            <div className="p-8 rounded-t-[60px] shadow-lg relative z-10 mt-[-50px]" style={{ backgroundColor: '#FAF9F6' }}>
                <h2 className="text-black text-3xl font-semibold mt-6 text-center">
                    The comprehensive commerce platform that integrates all aspects of retail.
                </h2>
                <p className="text-gray-700 mt-4 text-2xl text-center">
                    Market your products both online and offline. Reach customers nearby and around the world. Offer retail and wholesale options. Connect with buyers on both desktop and mobile platforms.
                </p>

                {/* Scrolling Image Gallery */}
                <div className="relative overflow-hidden mt-10 w-full flex justify-center">
                    <div className="flex animate-scroll space-x-6" style={{ minWidth: '1600px' }}>
                        {images.concat(images).map((image, index) => (
                            <div key={index} className="flex-shrink-0">
                                <img
                                    src={image}
                                    alt={`Gallery ${index + 1}`}
                                    className="w-[350px] h-[230px] object-cover rounded-lg shadow-lg"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <style jsx>{`
                    @keyframes scroll {
                        0% { transform: translateX(0); }
                        100% { transform: translateX(-1600px); }
                    }
                    .animate-scroll {
                        animation: scroll 25s linear infinite;
                    }
                `}</style>
            </div>
        </>
    );
}

export default Home;

