import React, { useState, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

function DraggableImage({ image, index, moveImage, deleteImage, isViewMode}) {
  const ref = React.useRef(null);

  // Set up drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Set up drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="w-24 h-24 relative border rounded"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <img 
        src={image.src} 
        alt={`uploaded-${index}`} 
        className="w-full h-full object-cover rounded"
      />
      
      {/* Delete Button */}
      {!isViewMode && (
        <div>
      <button
        onClick={() => deleteImage(index)}
        className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
        style={{ transform: 'translate(50%, -50%)' }}
      >
        ✕
      </button>

        </div>

      )}
    </div>
  );
}

export default DraggableImage;
