import React, { useEffect, useState } from "react";
import { FiMenu, FiSettings, FiLogOut } from "react-icons/fi";
import { MdCategory } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import apiClient from "../../api/apiClient";
import ProductForm from "../products/ProductForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomerHome from "../customer/CustomerHome"; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import Settings from "../settings/Settings"; // Adjust the path as h

const ShopDashboard = ({ shopData }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("All Products");
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMode, setViewMode] = useState("add"); // Controls ProductForm mode
  const [productData, setProductData] = useState({});
  // Default store link
  const navigate = useNavigate();
  // console.log(products);
  const handleGoToCustomerHome = () => {
    navigate("/customer-home", { state: { shopData } }); // Passing shopData as state
  };

  const handleSettingPage = () => {
    navigate("/settings", { state: { shopData } }); // Passing shopData as state
  };

  const [productDeletedPopup, setProductDeletedPopup] = useState({
    show: false,
    message: "",
    success: false,
  });

  // Fetch products on component mount or when shopData changes
  useEffect(() => {
    console.log('shop data = ' + JSON.stringify(shopData))
    if (shopData?.shopId) {
      console.log('trueeee')
      const fetchData = async () => {
        try {
          const response = await apiClient.get(
            `/shops/products?shopId=${shopData.shopId}`
          );
          console.log(response.data)
          setProducts(response.data.products);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      fetchData();
    } else {
      console.log("false")
    }
  }, [shopData]);
  // console.log(fetchData);

  const handleMenuToggle = (menu) => {
    setSelectedMenu(menu);
    if (menu === "Add New Product") {
      setViewMode("add");
      setProductData({}); // Reset data when adding a new product
    }
  };

  const handleMenusettingToggle = () => {
    setMenuOpen(!menuOpen); // Toggle the menu visibility
  };

  // const handleMenuItemClick = (menuItem) => {
  //   setSelectedMenu(menuItem);
  //   setMenuOpen(false); // Close the menu after selecting an item
  // };

  // const handleSaveSettings = () => {
  //   setIsEditing(false);
  //   // Here you can make an API call to save the updated contact number and store link
  // };

  // Filter products based on search query
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handlers for different actions
  const handlePreviewProduct = (product) => {
    setProductData(product);
    setViewMode("view");
    setSelectedMenu("Product Details"); // Update menu to show details view
  };

  const handleEditProduct = (product) => {
    setProductData(product);
    setViewMode("edit");
    setSelectedMenu("Edit Product"); // Update menu to show edit view
  };

  const handleLogout = () => {
    console.log('Handle logout')
    localStorage.removeItem('idToken');
    navigate('/login')
  }

  const handleDeleteProduct = async (productId) => {
    try {
      await apiClient.delete(`/shops/products/${productId}`);

      setProductDeletedPopup({
        show: true,
        success: true,
        message: "Product deleted successfully",
      });
      const response = await apiClient.get(
        `/shops/products?shopId=${shopData.shopId}`
      );
      setProducts(response.data.products); // Update the product list
    } catch (error) {
      console.error("Error when deleting product with: " + error);
      setProductDeletedPopup({
        show: true,
        success: false,
        message: "Unable to delete product",
      });
    } finally {
      setTimeout(() => {
        setProductDeletedPopup({ show: false, success: true, message: "" });
      }, 3000);
    }
    // Handle delete functionality here
  };

  return (
    <div className="h-screen flex">
      {/* Sidebar */}

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Menu Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => handleMenuToggle("All Products")}
          >
            {selectedMenu !== "All Products" && (
              <AiOutlineArrowLeft size={24} className="mr-2" />
            )}

            <span className="text-lg font-semibold ms-3">{selectedMenu}</span>
          </div>
          <button
            className="text-gray-700 hover:text-gray-900 focus:outline-none"
            onClick={handleMenusettingToggle}
          >
            <FiMenu size={28} />
          </button>
        </div>

        {menuOpen && (
          <div className="absolute right-4 top-16 bg-white border border-gray-300 shadow-xl rounded-lg w-56 z-10">
            {/* Shop Name at the top */}
            <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-4 py-3 rounded-t-lg flex items-center justify-between">
              <h2 className="text-lg font-bold truncate">
                {shopData.shopName}
              </h2>
            </div>
            {/* Menu Items */}
            <ul className="divide-y divide-gray-200">
              <li
                className="px-4 py-3 hover:bg-blue-50 flex items-center space-x-3 cursor-pointer transition-colors duration-300"
                onClick={handleSettingPage}
              >
                <FiSettings className="text-yellow-600" size={18} />
                <span className="font-medium text-gray-700">Settings</span>
              </li>
              <li
                className="px-4 py-3 hover:bg-blue-50 flex items-center space-x-3 cursor-pointer transition-colors duration-300"
                onClick={handleLogout}
              >
                <FiLogOut className="text-red-600" size={18} />
                <span className="font-medium text-gray-700">Sign Out</span>
              </li>
            </ul>
          </div>
        )}

        {/* Content Section */}
        <div className="p-6 bg-[#FAF9F6] h-full">
          {/* All Products View */}
          {selectedMenu === "All Products" && (
            <>
              <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
                <input
                  type="text"
                  placeholder="Search Products..."
                  className="px-4 py-2 border border-gray-300 rounded w-full sm:w-auto"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  onClick={() => handleMenuToggle("Add New Product")}
                  className="px-4 py-2 bg-blue-500 text-white rounded w-full sm:w-auto"
                >
                  + Add New Product
                </button>
              </div>

              {/* Product List */}
              {filteredProducts.length > 0 ? (
                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="px-6 py-3 text-left">Product Name</th>
                        <th className="px-6 py-3 text-left">Price</th>
                        <th className="px-6 py-3 text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredProducts.map((product) => (
                        <tr
                          key={product.id}
                          className="border-b hover:bg-gray-50"
                        >
                          <td className="flex items-center space-x-2">
                            <img
                              src={product.imageUrls[0]}
                              alt={product.name}
                              className="w-8 h-8 object-cover rounded-full ms-6 mt-4"
                            />
                            <span className="mt-4">{product.name}</span>
                          </td>
                          <td className="px-6 py-4">
                            ₹
                            {new Intl.NumberFormat("en-IN").format(
                              product.price
                            )}
                          </td>

                          <td className="px-6 py-4 flex justify-center items-center space-x-4">
                            <button
                              onClick={() => handlePreviewProduct(product)}
                              className="text-blue-600 hover:text-blue-800"
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            <button
                              onClick={() => handleEditProduct(product)}
                              className="text-yellow-600 hover:text-yellow-800"
                              aria-label="Edit Product"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              onClick={() => handleDeleteProduct(product.id)}
                              className="text-red-600 hover:text-red-800"
                              aria-label="Delete Product"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <p>No products available. Start adding products!</p>
                </div>
              )}
            </>
          )}

          {productDeletedPopup.show && (
            <div
              className={`fixed top-4 right-4 p-4 rounded shadow-md ${
                productDeletedPopup.success ? "bg-green-500" : "bg-red-500"
              } text-white`}
            >
              <p>{productDeletedPopup.message}</p>
            </div>
          )}

          {/* Add/Edit/View Product Form */}
          {selectedMenu !== "All Products" && (
            <ProductForm
              shopData={shopData}
              initialProductData={productData}
              viewMode={viewMode}
            />
          )}

          {selectedMenu == "Settings" && <Settings shopData={shopData} />}
        </div>
      </div>
    </div>
  );
};

export default ShopDashboard;
