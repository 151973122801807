import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import SignIn from './components/auth/Signin';
import Dashboard from './components/dashboard/Dashboard';
import AddShop from './components/shops/AddShop';
import ShopDashboard from './components/dashboard/ShopDashboard';
import CustomerHome from './components/customer/CustomerHome';
import Settings from './components/settings/Settings';
import CartPage from './components/shops/CartPage';

function App() {
  return (
    <Router>
      <div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-shop" element={<AddShop />} />
          
          <Route path="/settings" element={<Settings />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/myshop/:shopUrl" element={<CustomerHome />} />
        </Routes>
    </div>
    </Router>
  );
}

export default App;
